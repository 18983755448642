.my-carousel {
    border-radius: 20px;
    overflow: hidden;
}

.product-card {
    width: 200px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    text-align: center;
}

.product-image {
    width: 100%;
    height: auto;
}

.product-info {
    margin-top: 10px;
}

.product-rating {
    margin-top: 5px;
    color: gold;
}