.content-container {
    display: flex;
    flex-wrap: wrap;
}

.sidebar {
    flex: 1;
    max-width: 300px;
    margin-right: 20px;
}

.product-list {
    flex: 3;
}

.product-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.product-card {
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 10px;
    width: 100%;
    max-width: 220px;
    text-align: center;
    position: relative;
}

.fav-icon {
    position: absolute;
    top: 10px;
    left: 10px;
    color: black;
}

.product-link {
    text-decoration: none;
    color: black;
}

.product-image {
    width: 200px;
    height: 200px;
    border-radius: 8px;
}

.product-info {
    height: 50px;
    margin-top: 10px;
}

.product-name {
    margin: 10px 0;
}

.product-pricing {
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
}

.special-price {
    color: #ff0000;
}

.original-price {
    text-decoration: line-through;
}

.discount {
    color: green;
}

.add-to-cart {
    background-color: #00AFCA;
    color: white;
    margin-top: 10px;
    padding: 10px;
    border-radius: 8px;
    cursor: pointer;
}

.filter-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.filter-header .MuiIconButton-root {
    padding: 8px;
}

.filter-header h3 {
    margin: 0;
}

/* Responsive design */
@media (max-width: 768px) {
    .sidebar {
        max-width: 100%;
        margin-bottom: 20px;
    }

    .product-card {
        width: 100%;
        max-width: 100%;
    }

    .product-grid {
        justify-content: center;
    }

    /* Full-width drawer */
    .MuiDrawer-paper {
        width: 100%;
    }

    .filter-container {
        padding: 10px;
    }

    .filter-header {
        padding: 10px;
    }

    .filter-header h3 {
        font-size: 1.2em;
    }

    .filter-header .MuiIconButton-root {
        padding: 8px;
    }

    .AccordionDetails {
        padding: 8px 20px;
    }
}

@media (max-width: 600px) {
    .sidebar {
        width: 100%;
    }
}